<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../assets/img/about/banner.png" alt="" />
    </div>
    <div class="container">
      <div class="box">
        <div class="top">
          <div class="title">公司简介</div>
          <span class="line"></span>
        </div>
        <div class="p">
          <span
            >山西金卡易联商务有限公司成立于2006年，总部位于山西太原，注册资本9000万元。公司在开展业务的地市均设有分支机构，部分县级区域成立办事处，最大程度延伸企业服务范围。</span
          >
          <span
            >山西金卡易联作为首批通过中国银联业管委审核的“银联卡收单外包服务机构”、全国首批通过中国支付清算协会“收单外包服务机构备案”的企业、山西省重点扶持的创新科技企业，始终秉承“协作共赢”的经营理念、专业专注的服务态度，发扬固本开新，久久为功的奋斗精神，努力打造一流的商业银行金融服务专家，为合作伙伴提供优质服务、创造核心价值。</span
          >
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="container container_bg">
      <div class="box">
        <div class="time_axis">
          <div class="left_title_box">
            <div class="title">发展历程</div>
            <span class="line"></span>
          </div>
          <div class="left_content">
            <div class="show_date">{{ showYear }}</div>
            <div class="show_content">
              <span>{{ course.content }}</span>
            </div>
          </div>
          <div class="right_date">
            <ul>
              <li
                :class="{ active: index === currentIndex }"
                v-for="(item, index) in yearList"
                :key="item.id"
                @click="liClick(index, item.id)"
              >
                {{ item.year }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container">
      <div class="box">
        <div class="img_box">
          <img src="../assets/img/about/jg.png" alt="" />
        </div>
      </div>
    </div> -->
    <!-- 新闻活动 -->
    <div class="container">
      <div class="box">
        <div class="title">新闻活动</div>
        <div class="swiper_box">
          <div class="swiper-container1">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="slide_box">
                  <div class="">
                    <img src="../assets/img/partner/activity1.jpg" alt="" />
                  </div>
                  <div class="slidecontent">
                    <span class="name">北部湾银行</span>
                    <span>电子函证系统中标</span>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slide_box">
                  <div class="">
                    <img src="../assets/img/partner/activity2.jpg" alt="" />
                  </div>
                  <div class="slidecontent">
                    <span class="name">东莞农村商业银行</span>
                    <span>电子函证系统中标</span>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slide_box">
                  <div class="">
                    <img src="../assets/img/partner/activity3.jpg" alt="" />
                  </div>
                  <div class="slidecontent">
                    <span class="name">泉州银行</span>
                    <span>电子函证系统中标</span>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slide_box">
                  <div class="">
                    <img src="../assets/img/partner/activity4.jpg" alt="" />
                  </div>
                  <div class="slidecontent">
                    <span class="name">甘肃省联社</span>
                    <span>数字回单系统中标</span>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slide_box">
                  <div class="">
                    <img src="../assets/img/partner/activity5.jpg" alt="" />
                  </div>
                  <div class="slidecontent">
                    <span class="name">遂宁银行</span>
                    <span>电子函证系统中标</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系方式 -->
    <div class="container container_bg">
      <div class="box">
        <div class="title">联系方式</div>
        <div class="flex_box flex_box_w">
          <div class="left_list">
            <ul>
              <li>
                <div class="infoIcon">
                  <img src="../assets/img/partner/list2.png" alt="" />
                </div>
                <div class="info">
                  <span>网址</span>
                  <p>https://sx.cfcasmes.cn</p>
                </div>
              </li>
              <li>
                <div class="infoIcon">
                  <img src="../assets/img/partner/list3.png" alt="" />
                </div>
                <div class="info">
                  <span>电话</span>
                  <p>400-666-3349</p>
                </div>
              </li>
              <li>
                <div class="infoIcon">
                  <img src="../assets/img/partner/list1.png" alt="" />
                </div>
                <div class="info">
                  <span>地址</span>
                  <p>山西省太原市小店区南中环街529号清控创新基地D座1501</p>
                </div>
              </li>
              <!-- <li>
                <div class="infoIcon">
                  <img src="../../assets/img/partner/list4.png" alt="" />
                </div>
                <div class="info">
                  <span>邮箱</span>
                  <p>测试邮箱测试邮箱测试邮箱</p>
                </div>
              </li> -->
            </ul>
          </div>
          <div class="right_map">
            <img src="../assets/img/partner/map.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="box">
        <div class="top">
          <div class="title">团队介绍</div>
          <span class="line"></span>
        </div>
        <ul class="list">
          <li class="item">
            <div class="img">
              <img src="../assets/img/about/td1.png" alt="" />
            </div>
            <div class="tdjs">
              <span>山西金卡易联商务有限公司</span>
              <span
                >山西金卡易联商务有限公司成立于2006年，注册资本9000万元，全国首批通过银联业管委审核的“银联卡收单第三方服务机构”，全国首批通过中国支付清算协会“收单外包服务机构备案”的企业之一，商业银行金融服务专家、分期业务行业引领者，是山西省重点扶持的创新科技企业之一。</span
              >
            </div>
          </li>
          <li class="item">
            <div class="tdjs">
              <span>中融万博网络科技有限公司</span>
              <span
                >中融万博专注于银行数字凭证、函证深度数据挖掘和数字化、一个电话号码精准投递及客户服务体系建设，全力打造一号消息、一号密函、一号密蜂平台及系列知识产权平台，并以城商行、农商行、省联社等目标银行提供专业化的银行账单数据治理、符合监管要求的数据应用体系建设服务为抓手，推动省、市级网络生态建设，进而推动银行以及银行服务的企业、个人记账、报税等财税银相关业务数字化转型升级。</span
              >
            </div>
            <div class="img">
              <img src="../assets/img/about/td2.png" alt="" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="container">
      <div class="box">
        <div class="top">
          <div class="title">请对我们的服务进行评价</div>
        </div>
        <div class="evaluate_list">
          <div class="evaluate_item">
            <div class="evaluate_img">
              <img src="../assets/img/about/pj1.png" alt="" />
            </div>
            <div class="evaluate_title">工商社保</div>
          </div>
          <div class="evaluate_item">
            <div class="evaluate_img">
              <img src="../assets/img/about/pj2.png" alt="" />
            </div>
            <div class="evaluate_title">代帐报税</div>
          </div>
          <div class="evaluate_item">
            <div class="evaluate_img">
              <img src="../assets/img/about/pj3.png" alt="" />
            </div>
            <div class="evaluate_title">金融服务</div>
          </div>
          <div class="evaluate_item">
            <div class="evaluate_img">
              <img src="../assets/img/about/pj4.png" alt="" />
            </div>
            <div class="evaluate_title">知识产权服务</div>
          </div>
          <div class="evaluate_item">
            <div class="evaluate_img">
              <img src="../assets/img/about/pj5.png" alt="" />
            </div>
            <div class="evaluate_title">支付服务</div>
          </div>
          <div class="evaluate_item">
            <div class="evaluate_img">
              <img src="../assets/img/about/pj6.png" alt="" />
            </div>
            <div class="evaluate_title">法律服务</div>
          </div>
          <div class="evaluate_item">
            <div class="evaluate_img">
              <img src="../assets/img/about/pj7.png" alt="" />
            </div>
            <div class="evaluate_title">培训服务</div>
          </div>
          <div class="evaluate_item">
            <div class="evaluate_img">
              <img src="../assets/img/about/pj8.png" alt="" />
            </div>
            <div class="evaluate_title">科技服务</div>
          </div>
          <div class="evaluate_item">
            <div class="evaluate_img">
              <img src="../assets/img/about/pj9.png" alt="" />
            </div>
            <div class="evaluate_title">数字化服务</div>
          </div>
          <i></i><i></i><i></i>
        </div>

        <div class="evaluate_btn" @click="handleEvaluate">立即评价</div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {},
  props: {},
  data() {
    return {
      currentIndex: 0,
      showYear: "",
      course: {},
      yearList: [
        {
          id: 14,
          year: "2023",
          content: "扩项经营服务范围，办理代记账服务证书。开展代记账服务。",
        },
        // {
        //   id: 13,
        //   year: "2022",
        //   content:
        //     "中融万博投资设立广州智慧博纳AI公司、厦门壹号密巢公司;中融万博助力七部委发展函证、账单业务，已与16家银行签约合作;易联支付《支付业务许可证》第二次续展通过。",
        // },
        {
          id: 12,
          year: "2020",
          content: "金卡易联成为首批备案的“收单外包服务”机构。",
        },
        // {
        //   id: 11,
        //   year: "2018",
        //   content:
        //     "中融万博在北京成立，且自主研发的“银信邮”产品上线，万博“银信通”服务总客户量突破1500万，金卡易联投资入股静乐农商行。",
        // },
        // {
        //   id: 10,
        //   year: "2017",
        //   content:
        //     "易联支付《支付业务许可证》续展成功，金卡科技“网贷资金存管系统”成功上线。）",
        // },
        {
          id: 9,
          year: "2016",
          content:
            "获得中国银联业管委“银联卡收单外包服务机构注册登记认证”，准许在山西省、河北省范围内开展银行卡收单专业化服务；",
        },
        // {
        //   id: 8,
        //   year: "2015",
        //   content:
        //     "孵化了四家企业，分别为金卡科技，金卡众和，易联金服，易联众合）",
        // },
        {
          id: 7,
          year: "2014",
          content:
            "开发“资金归集系统”，极大的方便了集团资源整合、提升风险防范、有效利用资金、降低整体的资金成本。",
        },
        {
          id: 6,
          year: "2013",
          content:
            "先后为晋商银行开发机场贵宾卡系统、加油站加油打折、影院等娱乐场所门票等贵宾卡打折收单系统。",
        },
        {
          id: 5,
          year: "2012",
          content:
            "在机场开通为晋商银行贵宾卡提供优质贵宾服务，为晋商银行优质商户引流、为晋商银行持卡客户提供特惠服务。",
        },
        {
          id: 4,
          year: "2010",
          content:
            "开发的“见费出单系统”广泛应用到的中国人保、国寿财、太平等保险公司。规避保险公司在收取保费中产生的风险。",
        },
        {
          id: 3,
          year: "2006",
          content:
            "成立开发的“银行卡MIS POS收单系统”相继在各大百货商场、连锁超市、便利店、药店使用。",
        },
        // {
        //   id: 2,
        //   year: "2002",
        //   content:
        //     "公司年总营业额突破亿元。这一年公司取得SP资质，自主研发了“MIS商户收银一体化系统软件”，完成了山西省大型连锁超市和百货店的投入使用，同时“银行账务即时通(银信通)”上线，正式开始与银行合作短信业务。",
        // },
        // {
        //   id: 1,
        //   year: "1993",
        //   content: "杨社堂先生于1993年创立    山西万博电子信息实业有限公司",
        // },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleEvaluate() {
      window.open("http://218.26.36.12:33599/signInfo");
    },
    defShowYear() {
      this.showYear = this.yearList[this.currentIndex].year;
      this.course = this.yearList[this.currentIndex];
    },
    liClick(index, id) {
      this.currentIndex = index;
      this.showYear = this.yearList[index].year;
      this.course = this.yearList.find((item) => {
        return id == item.id;
      });
    },
    initSwiper() {
      new Swiper(".swiper-container1", {
        effect: "coverflow",
        slidesPerView: 3,
        loop: true,
        // initialSlide: 2,
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 60,
          modifier: 2,
          slideShadows: false,
        },
        observeParents: false, //注意这里！！
        observer: true, //也要注意这里！！
      });
    },
  },
  created() {
    this.defShowYear();
  },
  mounted() {
    this.initSwiper();
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.line {
  display: block;
  width: 40px;
  height: 1px;
  background: #055afe;
  margin-top: 10px;
}

.box .p {
  margin-top: 30px;
  font-size: 18px;
  color: #333333;
  line-height: 30px;
}

.box .p span {
  display: block;
  width: 100%;
  text-align: justify;
}

.img_box img {
  display: block;
  width: 100%;
}

.list {
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
  overflow: hidden;
}

.item {
  display: flex;
  justify-content: center;
}

.img {
  width: 50%;
  height: 290px;
}

.img img {
  display: block;
  width: 100%;
  height: 100%;
}

.tdjs {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #055afe;
  color: #fff;
}

.tdjs span {
  display: block;
  padding: 10px 30px;
  text-align: justify;
  font-size: 16px;
}

.tdjs span:first-child {
  /* font-size: 18px; */
  font-weight: 600;
  text-align: center;
}

.tdjs span:last-child {
  /* font-size: 18px; */
  text-indent: 2em;
}

/* 时间轴 */
.time_axis {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.left_title_box {
  position: absolute;
  top: 50%;
  left: -120px;
  width: 120px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -25px;
}
.right_date {
  overflow-y: scroll;
  width: 100px;
  height: 200px;
  padding-right: 5px;
}

.right_date::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  /* background-color: ; */
}

.right_date::-webkit-scrollbar-thumb {
  width: 1px;
  height: 10px;
  background-color: #d8d8d8;
}

.right_date ul {
  white-space: nowrap;
  position: relative;
  transition: transform 0.3s;
  z-index: 2;
  border-right: 1px solid #d8d8d8;
}

.right_date li {
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: #303133;
  position: relative;
  cursor: pointer;
}

.left_content {
  width: 80%;
  padding-left: 30px;
}

.show_date {
  font-size: 80px;
  font-weight: 600;
  color: #333333;
}

.show_content {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
}

.show_content span {
  display: block;
  text-align: justify;
}

.right_date .active {
  color: #055afe;
  border-right: 1px solid #055afe;
}

/* 新闻活动 */
.swiper-wrapper {
  width: 100%;
  height: 100%;
}

.swiper-wrapper img {
  display: block;
  width: 100%;
  height: 100%;
}
.swiper_box {
  margin-top: 40px;
}
.swiper-container1 {
  padding: 10px;
  overflow: hidden;
}

.slide_box {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
}

.slidecontent .name {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.slidecontent span {
  display: block;
  font-size: 12px;
  margin: 10px;
}

.left_list {
  padding: 20px;
  width: 400px;
  background: #fff;
}

.left_list ul li {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 20px;
}

.infoIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}

.infoIcon img {
  display: block;
  width: 100%;
  height: 100%;
}

.right_map {
  flex: 1;
  height: 100%;
}

.right_map img {
  display: block;
  width: 100%;
  height: 100%;
}

.flex_box {
  display: flex;
}

.flex_box_w {
  margin-top: 40px;
  height: 320px;
  border-radius: 8px;
  overflow: hidden;
}

.evaluate_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.evaluate_item {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.evaluate_img {
  width: 80px;
  height: 80px;
  padding: 20px;
}

.evaluate_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.evaluate_title {
  font-size: 18px;
  color: #949494;
}

.evaluate_list i {
  width: 15%;
}

.evaluate_btn {
  width: 205px;
  height: 58px;
  background: #055afe;
  border-radius: 29px;
  font-size: 20px;
  font-weight: bold;
  color: #fffefe;
  line-height: 58px;
  text-align: center;
  margin: 0 auto;
  margin-top: 70px;
  cursor: pointer;
}
</style>
